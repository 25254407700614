import styled from "styled-components";
import { theme } from "../styles/Global";
import { useState, useEffect } from "react";
import { Spinner } from "../svg_components";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { toast, ToastContainer } from "react-toastify";

const Main = ({ chapter, setChapter }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [loadingInfo, setLoadingInfo] = useState('');
  const [twitter, setTwitter] = useState(false);
  const [verify, setVerify] = useState(false);
  const [errorVerify, setErrorVerify] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [chapter1Problem, setChapter1Problem] = useState(false);
  const [errorInput, setErrorInput] = useState({id:0,text:''});
  const [walletError, setWalletError] = useState(false);
  const [profileInfo, setProfileInfo] = useState({
    id: 0,
    name: '',
    profile_image_url_https: '',
    wallet: ''
  });
  const [input1, setInput1] = useState("");
  const [input2, setInput2] = useState("");
  const [input3, setInput3] = useState("");
  const [calistir, setCalistir] = useState(0);
  const [walletInput, setWalletInput] = useState("");

  const param = useLocation()
  const history = useHistory()
  const api = 'https://fomo.lodosyazilim.com.tr'
  // useEffect(()=>{
  //   setTwitter(true)
  //   setChapter(3)
  // },[])
 

  useEffect(() => {
    if(walletInput !== ''){
      return
    }
    if(chapter >1){
      return
    }
    if (!profileInfo.invite1 || !profileInfo.invite2 || !profileInfo.invite3 ) {
        setChapter(1)
      return
    }
    else if (profileInfo.isPosted === null) {
      setInput1(profileInfo.invite1)
      setInput2(profileInfo.invite2)
      setInput3(profileInfo.invite3)
      setChapter(3)
      return
    }
    else if (!profileInfo.wallet) {
      setChapter(4)
      return
    } 
    else if (profileInfo.wallet && profileInfo.isPosted =='1' && profileInfo.invite1 && profileInfo.invite2 && profileInfo.invite3) {
      setChapter(5)
      return
    }
  }, [profileInfo])

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const params = Object.fromEntries(urlParams.entries())

    setIsLoading(true)
    // if (localStorage.getItem('key') !== null && localStorage.getItem('key') !== undefined) {
    //   axios.post(
    //     api + '/api/twitter-login', JSON.parse(localStorage.getItem('key'))
    //   )
    //     .then((res) => res.data)
    //     .then((res) => {
    //       setTwitter(true)
    //       setWalletInput(res.wallet)
    //       setProfileInfo(res)
    //       setIsLoading(false)
    //     })
    //     .catch(err => {
    //       console.log(err.response.data);
    //       if (err.response.data.error === 'You are not eligible') {
    //         const userData = JSON.parse(err.response.data.user);
    //         setProfileInfo(prev=>{
    //           return{
    //             id: userData.id,
    //             name: userData.name,
    //             profile_image_url_https: userData.profile_image_url_https,
    //             wallet: null,
    //             invite1:null,
    //             invite2:null,
    //             invite3:null,
    //           }
    //         })
    //         setChapter(1)
    //         console.log(userData);           
    //         setTwitter(true)
    //         setChapter1Problem(true)
    //       }
    //       setIsLoading(false)
    //     })
    // } else {
      axios.post(
        api + '/api/twitter-login', params
      )
        .then((res) => res.data)
        .then((res) => {
          console.log(params);
          localStorage.setItem('key', JSON.stringify(params))
          setTwitter(true)
          setProfileInfo(res)
          setIsLoading(false)
        })
        .catch(err => {
          console.log(err);
          // toast.error('Oops.. Something went wrong!');
          if (err.response.data.error === 'You are not eligible') {
            setChapter(1)
            const userData = JSON.parse(err.response.data.user);
            setProfileInfo(prev=>{
              return{
                id: userData.id,
                name: userData.name,
                profile_image_url_https: userData.profile_image_url_https,
                wallet: null,
                invite1:null,
                invite2:null,
                invite3:null,
              }
            })
            console.log(userData);
            setTwitter(true)
            setChapter1Problem(true)
          }
          setIsLoading(false)
        })
    

  }, [param])

  const connectWithTwitter = () => {
    setIsLoading(true)
    axios.get(
      api + '/api/twitter-login'
    )
      .then((res) => res.data)
      .then((res) => {
        window.location.href = res.twitterURL;

      })
      .catch(err => {
        console.log(err);
        toast.error('Oops.. Something went wrong!');
        setIsLoading(false)
      })
  }

  const inviteFriends = () => {
    setIsLoading(true)
    if (input1 == '' || input2 == '' || input3 == '') {
      toast.error('You must invite 3 friends.');
      setErrorInput(input1 == '' ? { id: 1, text: "you should fill in here!" } : input2 == '' ? { id: 2, text: "you should fill in here!" } : { id: 3, text: "you should fill in here!" })
      setIsLoading(false)
      return
    }
    axios.post(
      api + '/api/twitter-invite',
      { ...JSON.parse(localStorage.getItem('key')), invite1: input1, invite2: input2, invite3: input3 },
    )
      .then((res) => res.data)
      .then((res) => {
        setIsLoading(false)
        setChapter(prev => prev + 1);
      }).catch(err => {
        toast.error(err.response.data.error);
        if (err.response.data.error === "User already invited friends") {
          setChapter(prev => prev + 1)
        }
        if(err.response.data.error.substring(0, 5)=='First'){
          setErrorInput({
            id:1,
            text:"user can't be invited"
          })
        }else if(err.response.data.error.substring(0, 6)=='Second'){
          setErrorInput({
            id:2,
            text:"user can't be invited"
          })
        }else if(err.response.data.error.substring(0, 5)=='Third'){
          setErrorInput({
            id:3,
            text:"user can't be invited"
          })
        }else{
          setInput1('')
          setInput2('')
          setInput3('')
          setErrorInput({id:0,text:''})
        }
        setIsLoading(false)
      })
  }

  const checkPost = () => {
    setLoadingInfo('Checking...')
    setIsLoading(true)
      axios.post(
        api + '/api/twitter-postCheck',
        JSON.parse(localStorage.getItem('key')),
      )
        .then((res) => {

          window.open(`https://twitter.com/intent/tweet?text=Hey @${input1.startsWith("@") ? input1.substring(1) : input1}, @${input2.startsWith("@") ? input2.substring(1) : input2}, @${input3.startsWith("@") ? input3.substring(1) : input3}!🫵You're exclusively invited by me to join @fomosapiensnft_!❤️‍🔥 Secure your pre-sale spot by inviting 3 frens through the app.🚀Link in @fomosapiensnft_ bio.🔥Let's make wavessss!🌊💥%23FomosExcites`)
          // setLoadingInfo('Tweet sent. ✅')
          setTimeout(() => {
            setIsLoading(false)
            setLoadingInfo('')
          }, 4000);
                
          if (res.data.success) {
            setChapter(prev => prev + 1);
          }
        }).catch(err => {
          setErrorVerify(err.response.data.error)
          setVerify(false)
          setLoadingInfo('')
          console.log(err.response.data.error);
          setIsLoading(false)
          if (err.response.data.error === 'User already posted') {
            setChapter(4)
          } else {
            toast.error(err.response.data.error + ' If you posted tweet, wait and try again.');
          }
        })

  }

  const submitWallet = () => {
    setIsLoading(true)
    axios.post(
      api + '/api/twitter-wallet',
      { ...JSON.parse(localStorage.getItem('key')), wallet: walletInput },
    )
      .then((res) => res.data)
      .then((res) => {
        setChapter(5);
        setIsLoading(false);

        setProfileInfo(prev => {
          return {
            ...prev,
            wallet: walletInput,
          }
        })
        setWalletError(false);
      }).catch(err => {
        console.log(err);
        toast.error('Oops.. Something went wrong!');
        setWalletError(err.response.data.error);
        setIsLoading(false)
      })
  }

  return <BannerStyled>
    <ToastContainer
      position="top-right"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      theme="dark"
    />
    {isLoading &&
      <div className="loading">
        <h3>{loadingInfo}</h3>

        <Spinner />
      </div>
    }

    <img className="background" src="/images/background.jpg" alt="" />
    {!twitter ? <div className="container">
      <div className="title">
        FOMO SAPIENS
      </div>
      <div className="buttons">
      <div className="top">
          <div>
            get invited
            <svg xmlns="http://www.w3.org/2000/svg" width="23" height="24" viewBox="0 0 23 24">
              <text id="_" data-name="➤" transform="translate(0 20)" font-size="25" font-family="ZapfDingbatsITC, Zapf Dingbats"><tspan x="0" y="0">➤</tspan></text>
            </svg>
          </div>
          <div>
            invite frens<svg xmlns="http://www.w3.org/2000/svg" width="23" height="24" viewBox="0 0 23 24">
              <text id="_" data-name="➤" transform="translate(0 20)" font-size="25" font-family="ZapfDingbatsITC, Zapf Dingbats"><tspan x="0" y="0">➤</tspan></text>
            </svg>
          </div>
          <div>
            submit wallet adress for pre-sale
           
          </div>
        </div>
        <div className="connect" onClick={() => {
          connectWithTwitter()
        }}>
          CONNECT WITH
          <svg fill="#ffff" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100" height="100" viewBox="0 0 30 30"><path d="M26.37,26l-8.795-12.822l0.015,0.012L25.52,4h-2.65l-6.46,7.48L11.28,4H4.33l8.211,11.971L12.54,15.97L3.88,26h2.65 l7.182-8.322L19.42,26H26.37z M10.23,6l12.34,18h-2.1L8.12,6H10.23z"></path></svg>
        </div>
      </div>
      <div className="forniture">
        <img className="chair" src="/images/koltuklu.png" alt="" />
        <img className="path" src="/images/Path.png" alt="" />
      </div>
    </div> :
      <div className="container-2">
        <div className="titles-2">
          <div className="profile">
            <img src={profileInfo.profile_image_url_https.replace('_normal.jpg','.jpg')} />
            <p>{profileInfo.name}</p>
          </div>
          <div className="sign-out" onClick={() => {
            setTwitter(false);
            setChapter(1)
            localStorage.removeItem('key')
          }}>
            <p>sign out</p>
          </div>
        </div>
        {chapter === 1 && !chapter1Problem && <div className="card-container">

          <div className="level">
            <img draggable="false" className="chapter" src={`/images/Group1.png`} />
          </div>
          <div className="desc" style={{ top: '23vh' }}>
            <p>hello <b>{profileInfo.name}</b>, you're <span>eligible</span> to invite!</p>
          </div>
          <div className="buttons">
            <div className="next" style={{ position: 'relative' }} onClick={() => setChapter(prev => prev + 1)}>
            <p> NEXT STEP</p>
            </div>
          </div>
        </div>}
        {chapter === 1 && chapter1Problem && <div className="card-container">

          <div className="level">
            <img draggable="false" className="chapter" src={`/images/Group1.png`} />
          </div>
          <div className="desc" style={{ top: '23vh' }}>
            <p>hello <b>{profileInfo.name}</b>, unfortunately you're <span style={{ color: '#5c011b' }}>not eligible</span></p>
          </div>
          <div className="buttons">
            <div className="next" style={{ position: 'relative' }} onClick={() => {
              window.open('https://docs.google.com/forms/d/e/1FAIpQLSfo5LBHuzaHcUtxgfrudRP-VcNAox5w70Tv2ahy8GIuE8gYVA/viewform')
            }}>
              <p>REPORT ISSUE</p>
            </div>
            <div className="next" style={{ position: 'relative', marginLeft: '40px' }} onClick={() => {
              //  setChapter(prev => prev + 1)
              window.open('https://twitter.com/search?q=%23FomosExcites&src=typed_query&f=top#FomosExcites%20%20&src=typed_query')
            }}>

             <p> FIND AN INVITOR</p>
            </div>
          </div>
        </div>
        }
        {chapter === 2 && <div className="card-container">

          <div className="level">
            <img draggable="false" className="chapter" src={`/images/Group2.png`} />
          </div>
          <div className="desc">
            <p>you must pick three <b>uninvited</b> X fren for your invitation</p>
          </div>
          <div className="inputs">
            <input
              placeholder="@username1"
              type="text"
              value={input1}
              onChange={(e) => {
                if (e.target.value.length < 18) {
                  let newValue = e.target.value;
                  if (!newValue.startsWith('@')) {
                    newValue = '@' + newValue;
                  }
                  setInput1(newValue);
                }
              }}
            />
            {errorInput.id === 1 && <div className="error" style={{ top: '-3.2vh', left: '26.5vw' }}>
              <p>{errorInput.text}</p>
              <span>!</span>
            </div>}
            <input
              placeholder="@username2"
              type="text"
              value={input2}
              onChange={(e) => {
                if (e.target.value.length < 18) {
                  let newValue = e.target.value;
                  if (!newValue.startsWith('@')) {
                    newValue = '@' + newValue;
                  }
                  setInput2(newValue);
                }
              }}
            />
            {errorInput.id === 2 && <div className="error" style={{ top: '-3.2vh', left: '44.5vw' }}>
              <p>{errorInput.text}</p>
              <span>!</span>
            </div>}
            <input placeholder="@username3"
              type="text"
              value={input3}
              onChange={(e) => {
                if (e.target.value.length < 18) {
                  let newValue = e.target.value;
                  if (!newValue.startsWith('@')) {
                    newValue = '@' + newValue;
                  }
                  setInput3(newValue);
                }
              }}
            />
            {errorInput.id === 3 && <div className="error" style={{ top: '-3.2vh', left: '62.5vw' }}>
              <p>{errorInput.text}</p>
              <span>!</span>
            </div>}

          </div>
          <div className="buttons" style={{ top: '30vh' }}>
            <div className="next" onClick={() => inviteFriends()}>
            <p>NEXT STEP</p>
            </div>
          </div>
        </div>}
        {chapter === 3 && <div className="card-container">

          <div className="level">
            <img draggable="false" className="chapter" src={`/images/Group3.png`} />
          </div>
          <div className="desc" style={{ top: '23vh' }}>
            <p>let your frens <b>know</b> about your invitation!</p>
            {/* {errorVerify !== '' && <div className="warning">
              <svg id="Group_1108" data-name="Group 1108" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="54.684" height="49" viewBox="0 0 54.684 49">
                <defs>
                  <clipPath id="clip-path">
                    <rect id="Rectangle_441" data-name="Rectangle 441" width="54.684" height="49" transform="translate(0 0)" fill="#c52c3b" />
                  </clipPath>
                </defs>
                <g id="Group_1107" data-name="Group 1107" transform="translate(0 0)" clip-path="url(#clip-path)">
                  <path id="Path_440" data-name="Path 440" d="M53.894,40.163,32.042,2.828a5.7,5.7,0,0,0-9.879.059L.756,40.479a5.7,5.7,0,0,0,4.991,8.526l43.259-.257a5.7,5.7,0,0,0,4.889-8.585M27.471,42.9a3.138,3.138,0,1,1,3.119-3.157A3.138,3.138,0,0,1,27.471,42.9m4.548-26.7-1.485,14.13a3.138,3.138,0,0,1-6.276.037L22.6,16.259a4.707,4.707,0,0,1,9.414-.056" transform="translate(0 -0.005)" fill="#c52c3b" />
                </g>
              </svg>
              Opps, there is an error, can you please try post again?</div>
              } */}
            {/* {verify && verify !== 'error' && <div className="warning" style={{ color: '#4CAF50' }}>
              <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="65" height="65" viewBox="0 0 65 65">
                <defs>
                  <clipPath id="clip-path">
                    <rect id="Rectangle_442" data-name="Rectangle 442" width="65" height="65" transform="translate(-0.118 -0.118)" fill="none" />
                  </clipPath>
                </defs>
                <g id="Group_1110" data-name="Group 1110" transform="translate(-0.382 -0.382)">
                  <g id="Group_1109" data-name="Group 1109" transform="translate(0.5 0.5)" clip-path="url(#clip-path)">
                    <path id="Path_441" data-name="Path 441" d="M32.382,0A32.382,32.382,0,1,0,64.764,32.382,32.382,32.382,0,0,0,32.382,0M54.194,21.46,31.805,43.849l0,0-6.2,6.2,0,0-6.186-6.186-8.85-8.85a1.921,1.921,0,0,1,0-2.717l3.482-3.482a1.921,1.921,0,0,1,2.717,0L24.26,36.3a1.922,1.922,0,0,0,2.717,0l21.03-21.03a1.921,1.921,0,0,1,2.717,0l3.469,3.469a1.921,1.921,0,0,1,0,2.717" transform="translate(-0.456 -0.456)" fill="#5cb85c" />
                  </g>
                </g>
              </svg>
              Please click the verify button to check the tweet and proceed</div>
              } */}
          </div>
          <div className="buttons" style={{ gap: '15px' }}>
            <div className="next" onClick={() => {
              checkPost()
// window.open(`https://twitter.com/intent/tweet?text=Hey @${input1.startsWith("@") ? input1.substring(1) : input1}, @${input2.startsWith("@") ? input2.substring(1) : input2}, @${input3.startsWith("@") ? input3.substring(1) : input3}! 🚀 I invited you for @fomosapiensnft_ 🚀&url=https://fomo-sapiens-two.vercel.app/&media=https://via.placeholder.com/250/FF5733/FFFFFF?text=Sample+Image`)
//               setVerify(true)
//               setErrorVerify('')
            }}>
              <p>POST GENERATED TWEET</p>
              {/* <a target="_blank" href="https://twitter.com/intent/tweet?text=Hello%20World&url=https://example.com&media=https://compote.slate.com/images/22ce4663-4205-4345-8489-bc914da1f272.jpeg">Tweet with Image</a> */}
            </div>
            {/* <div className="next" style={verify && verify !== 'error' ? { cursor: 'pointer' } : { opacity: '0.15', cursor: 'not-allowed' }} onClick={() => { if (verify && verify !== 'error') checkPost() }} >
              <span >Verify & Proceed</span>
            </div> */}
          </div>
        </div>}
        {chapter === 4 && <div className="card-container">

          <div className="level">
            <img draggable="false" className="chapter" src={`/images/Group4.png`} />
          </div>
          <div className="desc" style={{ position: 'relative', top: '17vh' }}>
            <p>enter your <b>solana</b> wallet adress for <b>pre-sale allocation</b></p>
          </div>
          <div className="inputs" >
            <input
              placeholder="HN7cABqLq46Es1jh92dQQisAq662SmxELLLsHHe4YWrH"
              style={{ width: '28vw' }}
              type="text"
              value={walletInput}
              onChange={(e) => setWalletInput(e.target.value)}
            />

          </div>
          <div style={{ top: '30vh' }} className="buttons">
            <div className="next" style={walletInput.length <= 44 && walletInput.length>=32 ? { cursor: 'pointer' } : { backgroundColor:'#CF6F8B', border:'3px solid #7F0024', cursor: 'not-allowed' }} onClick={() => {
              if(walletInput.length <= 44 && walletInput.length>=32){
                submitWallet();
              }
            }}>
              <p> SUBMIT</p>
            </div>
          </div>
        </div>}
        {chapter === 5 && <div className="card-container">
          <div className="last">
            <div className="last_card">
              <img src="/images/LogoIcon.png" />
              <div className="card_desc">
                <p><b>invitor sapien:</b></p>
                <p>{profileInfo.name}</p>
                <p><b>invited sapiens:</b></p>
                <p>{profileInfo.invite1 ?? input1}, {profileInfo.invite2 ?? input2}, {profileInfo.invite3 ?? input3}</p>
                <p><b>status:</b></p>
                <p>secured</p>
              </div>
            </div>
            <div className="congrats">
              <h1>congrats!</h1>
              <p>you’ve secured your pre-sale allocation
                to become a fomo sapien. the frens that you've invited can also secure theirs by completing the same steps as you.</p>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <input readOnly value={profileInfo.wallet} type={showPassword ? 'text' : 'password'} style={{ width: '36vh' }} />
                <div onClick={() => setShowPassword(prev => !prev)}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="14.444" height="13" viewBox="0 0 14.444 13">
                    <path id="visibility_off_FILL0_wght400_GRAD0_opsz24" d="M49.914-841.106l-.952-.952a1.642,1.642,0,0,0-.443-1.444,1.578,1.578,0,0,0-1.527-.525l-.952-.952a2.786,2.786,0,0,1,.566-.2,2.758,2.758,0,0,1,.616-.066,2.849,2.849,0,0,1,2.093.862,2.849,2.849,0,0,1,.862,2.093,2.758,2.758,0,0,1-.066.616A2.785,2.785,0,0,1,49.914-841.106Zm2.1,2.068-.952-.919A7.187,7.187,0,0,0,52.171-841,5.841,5.841,0,0,0,53-842.288a6.454,6.454,0,0,0-2.355-2.634,6.242,6.242,0,0,0-3.422-.977,6.6,6.6,0,0,0-.936.066,6.32,6.32,0,0,0-.9.2l-1.018-1.018a7.275,7.275,0,0,1,1.379-.419,7.609,7.609,0,0,1,1.477-.139,7.455,7.455,0,0,1,4.415,1.371,7.815,7.815,0,0,1,2.807,3.554,7.679,7.679,0,0,1-.993,1.8A7.227,7.227,0,0,1,52.015-839.038ZM52.343-835l-2.758-2.725a7.878,7.878,0,0,1-1.157.271,7.872,7.872,0,0,1-1.206.09,7.455,7.455,0,0,1-4.415-1.371A7.815,7.815,0,0,1,40-842.288a7.781,7.781,0,0,1,.87-1.617,7.558,7.558,0,0,1,1.2-1.338l-1.806-1.838.919-.919,12.081,12.081Zm-9.356-9.323a7.052,7.052,0,0,0-.87.936,5.92,5.92,0,0,0-.673,1.1,6.454,6.454,0,0,0,2.355,2.635,6.242,6.242,0,0,0,3.422.977,4.895,4.895,0,0,0,.64-.041q.312-.041.64-.09l-.591-.624a3.01,3.01,0,0,1-.345.074,2.322,2.322,0,0,1-.345.025,2.849,2.849,0,0,1-2.093-.862,2.849,2.849,0,0,1-.862-2.093,2.323,2.323,0,0,1,.025-.345,3.011,3.011,0,0,1,.074-.345ZM48.223-842.8ZM45.745-841.566Z" transform="translate(-40 848)" fill="#919191" />
                  </svg>
                </div>
              </div>
              <div className="edit" onClick={() => setChapter(4)}>
                edit address
              </div>
            </div>
          </div>
        </div>
        }
      </div>

    }


  </BannerStyled>
};

export default Main;
export const BannerStyled = styled.div`
  position: relative;
  z-index: 2;
  height: 100vh;
  overflow: hidden;
  .background {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
    width: 100%;
    height: 100%;
  }
  .container{
    transition: all 0.3s ease-in-out;
    position: relative;
    background-color: rgba(255, 255, 255, 0.9); 
    width: 100%;
    height: 100%;
    z-index: 10;
    .title{
      position: relative;
      font-size: 11vw;
      font-family: regular-grotesk;
      opacity: 1;
      color: black;
      top: 14vh;
      width: 100%;
      text-align: center;
      @media (max-width: 920px) {
        font-size: 10vh;
      }
    }
    .buttons{
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      top: 14vh;
      z-index: 99;
      .top{
        display: flex;
        flex-direction: row;
        div{
          margin-inline: 1vh;
          font-size: 4vh;
          font-family: light-grotesk;
          font-weight: 800;

          svg{
            margin-left: 2vh;
            position: relative;
            top: 0.25vh;
            width: 2.2vh;
            height: 2.2vh;
          }
        }
      }
      .connect{
        background-color: black;
        border-radius: 4vh;
        position: relative;
        cursor: pointer;
        top: 3vh;
        width: auto;
        height: auto;
        z-index: 99;
        padding: 0.8vh;
        padding-inline: 3vh;
        font-family: light-grotesk;
        text-align: center;
        color: white;
        padding-top: 0.8vh;
        font-size: 3vh;
        transition: all 0.3s ease-in-out;
        :hover{
          opacity: 0.7;
        }
        svg{
          position: relative;
          width: 3vh;
          height: 3vh;
          top: 0.5vh;
          left: 1vh;
        }
      }
    }
    .forniture{
      transition: all 0.3s ease-in-out;
      .chair{
        width: auto;
        height: 50vh;
        position: absolute;
        left: 50%;
        bottom: 15px;
        z-index: 2;
        transform: translateX(-50%);
      }
      .path{
        width: 100vw;
        height: 18vh;
        position: absolute;
        left: 50%;
        bottom: 0px;
        z-index: 1;
        transform: translateX(-50%);
      }
    }
  }
  .container-2{
    transition: all 0.3s ease-in-out;
    position: relative;
    margin-inline: 13vw;
    margin-block: 17vh;
    width: 74vw;
    height: 64vh;
    background-color: white;
    border-radius: 5vh;
    z-index: 10;
    .titles-2{
      display: flex;
      justify-content: space-between;
      margin-inline: 3vw;
      position: relative;
      top: 4vh;
      .profile{
        display: flex;
        img{
          border-radius: 50%;
          width: 8vh;
          height: 8vh;
        }
        p{
          font-size: 2.3vh;
          font-family: bold-segoe;
          margin-top: 3vh;
          margin-left: 2vh;
        }
      }
      .sign-out{
       position: relative;
       cursor: pointer;
        font-size: 2.3vh;
        font-family: light-grotesk;
        margin-top: 1vh;
        text-align: center;
        height: 5vh;
        width: 18vh;
        color: white;
        border-radius: 10vh;
        background-color: black;
        transition: all 0.3s ease-in-out;
       display: flex;
       justify-content: center;
       align-items: center;
       p{
        font-weight: 300;
       }
        :hover{
          opacity: 0.7;
        }
      }
    }
    .card-container{
      transition: all 0.3s ease-in-out;
      animation: start 1s ease-in-out;
    .level{
      transition: all 0.3s ease-in-out;
      img{
        transition: all 0.3s ease-in-out;
        position: relative;
        width: 60vw;
        top: 12vh;
        left: 6vw;
      }
    }
    .desc{
        position: relative;
        text-align: center;
        font-family: regular-segoe;
        top: 17vh;
        font-size: 3.5vh;
        span{
          font-weight: 700;
          color: #1f3823;
          position: relative;
        }
        h4{
          font-size: 3.6vh;
          padding-top: 20px;
          font-weight: 400;
        }
        .warning{
          svg{
            width: 30px;
            height: 30px;
            position: relative;
            top: 5px;
            right: 10px;
          }
          width: 100%;
          position: absolute;
          left: 50%;
          font-size: 3vh;
          font-weight: 600;
          top: 7vh;
          color: #DD4F4F;
          transform: translate(-50%);
        }
    }
    .buttons{
        position: relative;
        top: 33vh;
        display: flex;
        justify-content: center;
        
      .next{
        cursor: pointer;
        text-align: center;
        background-color: #7F0024;
        font-size: 2.5vh;
        line-height: 3vh;
        font-family: light-grotesk;
        outline-style: none;
        color: white;
        padding-block: 1.2vh;
        padding-inline: 3.5vh;
        font-weight: 300;
        border-radius: 3vh;
        transition: all 0.3s ease-in-out;
        a{
          color: white;
        }
        :hover{
          opacity: 0.7;
        }
      }
    }
    .inputs{
      position: relative;
        top: 22vh;
        height: 3vh;
        gap: 2vw;
        display: flex;
        justify-content: center;
      flex-direction: row;
      input{
        height: 6vh;
        width: 16vw;
        padding: 2vh;
        border: 0.8px solid #707070;
        color: #707070;
        font-size: 1.98vh;
        border-radius: 10px;
        opacity: 1;
        font-family: regular-segoe;
        ::placeholder {
         opacity: 0.5; 
        }
        :nth-child(4)::placeholder {
         opacity: 0.4; 
         text-align: center;
        }
      }
    }
  }
  .error{
    position: absolute;
    border: 1px solid #707070;
    border-radius: 1vh;
    p{
      font-size: 1.4vh;
      font-family: regular-segoe;
      color: #707070;
      padding: 0.7vh;
    }
    span{
      position: absolute;
      border: 1px solid #707070;
      color: white;
      left: -1vh;
      width: 2vh;
      height: 2vh;
      background-color: #7F0024;
      text-align: center;
      padding-top: 0.2vh;
      font-size: 1.6vh;
      border-radius: 50%;
    }
  }
  .last{
    display: flex;
    flex-direction: row;
    margin-top: 5vh;
    justify-content: center;
    .last_card{
      background-color: #F1F1F1;
      p{
        padding-inline: 1vh;
        padding-block: 0.5vh;
        font-family: regular-segoe;
        font-size: 1.4vh;
      }
      p:last-child{
        padding-bottom: 2vh;
      }
      img{
        height: 30vh;
        margin-bottom: 1vh;
      }
    }
    .congrats{
      margin-left: 4vw;
      margin-top: 8vh;
      
      h1{
        font-size: 6vh;
        font-family: semibold-grotesk;
        color: #7F0024;
        margin-bottom: -2vh;
      }
      p{
        width: 36vh;
        font-family: regular-segoe;
        font-size: 2vh;
        line-height: 3vh;
        margin-top: 2vh;
      }
      input{
        background-color: #F1F1F1;
        border: 0px;
        border-radius: 1px;
        height: 4vh;
        padding: 1vh;
        margin-top: 2vh;
        font-size: 2vh;
      }
      svg{
        margin-left: 0.4vw;
        transition: all 0.3s ease-in-out;
        position: relative;
        top: 3vh;
        width: 2vh;
        height: 2vh;
        cursor: pointer;
        :hover{
          opacity: 0.7;
        }      }
      .edit{
        background-color: #7F0024;
        color: white;
        border-radius: 3vh;
        height: 3.2vh;
        font-size: 2vh;
        font-family: regular-segoe;
        width: 8vw;
        min-width: 14vh;
        text-align: center;
        padding-top: 0.5vh;
        margin-top: 2vh;
        transition: all 0.3s ease-in-out;
        cursor: pointer;
        :hover{
          opacity: 0.7;
        }
      }
    }
  }
  }

  @keyframes start {
    from{
      opacity: 0;
    }to{
      opacity: 1;
    }
  }
  .loading{
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 100000;
    height: 100%;
    width: 100%;
    h3{
      position: absolute;
      top: 40%;
      left: 50%;
      transform: translate(-50%,-50%);
      font-size: 22px;
      color: white;
    }
    svg{
      position: absolute;
      width: 200px;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      z-index: 100000;
      height: 200px;
    }
  }
`;
