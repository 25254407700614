import styled from "styled-components";
import { useState, useEffect } from "react";
import axios from "axios";
import useDeviceDetect from "../useDeviceDetect";
import AddModal from "../components/AddModal";
import Warning from "../components/Warning";
import { Spinner } from "../svg_components";
import { toast, ToastContainer } from "react-toastify";
import LoginPage from "./LoginPage";

const AdminPage = () => {
    let isMobile = useDeviceDetect();
    const [isModalOpened, setIsModalOpened] = useState(false)
    const [isInviteOpened, setIsInviteOpened] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [isWarningOpened, setIsWarningOpened] = useState(false)
    const [requestList, setRequestList] = useState([])
    const [deleteAllList, setDeleteAllList] = useState([])
    const [currentSlideIndex, setCurrentSlideIndex] = useState(1)
    const [adminControl, setAdminControl] = useState('')
    const [allPages, setAllPages] = useState(1)
    const [selectedItems, setSelectedItems] = useState([]);
    const [allCheck, setAllCheck] = useState(false);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [goToPage, setGoToPage] = useState('');

    const [warningInfo, setWarningInfo] = useState({
        Text: 'Are you sure you want to delete ...  ?',
        Button: 'OKEY',
        exported: false,
        Function: () => {
            console.log('calisti');
            setIsWarningOpened(false)
        },
    })
    const [pageTableList, setPageTableList] = useState([
        {
            screen_name: '',
            invite1: '',
            invite2: '',
            invite3: '',
            wallet: '',
            profile_image_url_https: '',
        }
    ])

    const api = 'https://fomo.lodosyazilim.com.tr'

    useEffect(() => {
        if (localStorage.getItem('admin')) {
            setIsLoggedIn(true)
            setAdminControl({ user: 'admin', password: 'F0mo.' });
        }
    }, [])

    useEffect(() => {
        if (adminControl.user !== '') {
            console.log(adminControl);
            axios.get(
                api + '/api/listUser',
                {
                    auth: {
                        username: adminControl.user,
                        password: adminControl.password
                    }
                }
            )
                .then((res) => res.data)
                .then((res) => {
                    setRequestList([...res])
                })
                .catch(err => {
                    toast.error(err.response.data.error)
                })
        }
    }, [adminControl, isWarningOpened, isModalOpened])




    useEffect(() => {
        setAllPages(Math.ceil(requestList.length / 10))
        let pageList;
        pageList = requestList.slice((currentSlideIndex - 1) * 10, currentSlideIndex * 10);
        setPageTableList(pageList)
    }, [requestList, currentSlideIndex])



    const handleSelectItem = (unique_id) => {
        const selectedItemIndex = selectedItems.findIndex(item => item.unique_id === unique_id);
        if (selectedItemIndex === -1) {
            setSelectedItems([...selectedItems, { unique_id }]);
        } else {
            const updateSelectedItems = [...selectedItems];
            const updatedItems = updateSelectedItems.filter((item) => item.unique_id !== unique_id)
            setSelectedItems(updatedItems);
        }
    };

    const handleDeleteItems = () => {
        setSelectedItems([]);
    };

    useEffect(() => {
        if (requestList.length === selectedItems.length) {
            if (requestList.length !== 0) {
                setAllCheck(true)
            }
        } else {
            setAllCheck(false)
        }
    }, [selectedItems, requestList])

    const selectAllItems = () => {
        const idArray = requestList?.map(item => { return { unique_id: item.unique_id } });
        if (allCheck) {
            setSelectedItems([])
        } else {
            setSelectedItems(idArray)
        }
    };
    const handleInputChange = (e) => {
        setGoToPage(e.target.value);
    };

    const handleGoToPage = () => {
        const pageNumber = parseInt(goToPage);
        if (pageNumber > 0 && pageNumber <= allPages) {
            setIsLoading(true);
            setTimeout(() => {
                setCurrentSlideIndex(pageNumber);
                setIsLoading(false);
                window.scrollTo(0, 0);
            }, 200);
        } else {
            alert('Geçerli bir sayfa numarası girin.');
        }
    };


    return (
        <AdminPageStyled>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                theme="dark"
            />
            {isWarningOpened && <Warning warningInfo={warningInfo} setIsWarningOpened={setIsWarningOpened} adminControl={adminControl} />}
            {isModalOpened && <AddModal isModalOpened={isModalOpened} setIsModalOpened={setIsModalOpened} adminControl={adminControl} />}
            {isLoading &&
                <div className="loading">
                    <Spinner />
                </div>
            }
            {!isLoggedIn ? <LoginPage adminControl={adminControl} setAdminControl={setAdminControl} setIsLoggedIn={setIsLoggedIn} setIsLoading={setIsLoading} /> : <div className="container">
                <div className="header">
                    <div className="application">
                        <h1> Applications ({requestList.length})</h1>
                        {/* <p>{requestList.length}</p> */}
                    </div>
                    <div className="selectAll">
                        <h1>Admin</h1>
                        <div className="button-container">
                            <div style={{ display: 'flex', gap: '10px' }}>
                                <div className="button1" onClick={() => setIsModalOpened(true)}> Add New Member</div>
                            </div>
                            <div className="button2" onClick={() => {
                                if (selectedItems.length > 0) {
                                    setWarningInfo({
                                        Text: 'Are you sure you want to delete selected applicants?',
                                        Button: 'DELETE',
                                        Function: () => {
                                            console.log('calisti');
                                            setIsWarningOpened(false)
                                            const ids = selectedItems.map(e => e.unique_id)
                                            axios.post(api + '/api/deleteUser', {
                                                ids: ids
                                            }, {
                                                auth: {
                                                    username: adminControl.user,
                                                    password: adminControl.password
                                                }
                                            }).then(res => {
                                                toast.success('Successfully deleted.')
                                                setIsWarningOpened(false)
                                            })
                                                .catch(e => toast.error(e.response.data.error))
                                        }
                                    })
                                    setIsWarningOpened(true)
                                } else {
                                    setWarningInfo({
                                        Text: 'There is no one selected. You must select people to delete.',
                                        Button: 'OKEY',
                                        Function: () => {
                                            setIsWarningOpened(false)
                                        }
                                    })
                                    setIsWarningOpened(true)
                                }
                            }}> Select All to Delete</div>
                            <div className="button3" onClick={() => {
                                // csv,xlsx,xls,json
                                setWarningInfo({
                                    Text: '',
                                    Button: '',
                                    exported: true,
                                    Function: () => {
                                        setIsWarningOpened(false)
                                    }
                                })
                                setIsWarningOpened(true)
                            }}
                            >
                                <svg width="40" height="40" viewBox="0 0 1 1" xmlns="http://www.w3.org/2000/svg"><path fill="#ffff" d="M.777.068.715 0H.233a.045.045 0 0 0-.048.046v.181h.068V.083Q.255.07.267.069h.345Q.623.068.623.077v.241h.246q.014 0 .013.012v.589q0 .016-.013.014H.267A.014.014 0 0 1 .253.919V.864H.185v.085C.184.979.2 1 .233 1h.67C.938 1 .95.975.95.952V.259L.933.24zM.692.076l.019.022.13.143.007.009H.715Q.7.25.696.245T.691.229zM.637.533h.229V.6H.637zm0-.133h.229v.067H.637zm0 .267h.229v.067H.637zM.05.281v.533h.523V.281zm.262.31L.28.64h.032V.7H.151L.268.525.164.367h.087l.061.092.061-.092h.086L.356.525.472.7H.383z" /></svg>
                                EXPORT
                            </div>

                        </div>
                    </div>
                </div>
                {isMobile || window.innerWidth < window.innerHeight ? <div className="tableContainer">
                    <table>
                        <thead>
                            <tr>
                                <th>
                                    <StyledCheckbox
                                        type="checkbox"
                                        checked={allCheck}
                                        onChange={() => { selectAllItems() }}
                                    />

                                </th>
                                <th>Applicants</th>
                                <th>Invite 1</th>
                                <th>Invite 2</th>
                                <th>Invite 3</th>
                                <th>Applicants Wallet</th>
                                <th>Delete</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className="bosluk"></tr>

                            {pageTableList.map((player, index) => {

                                return (
                                    <tr key={Math.random() * 1000}>

                                        <td style={{ width: '4%' }}>
                                            <StyledCheckbox
                                                type="checkbox"
                                                checked={selectedItems.some(selectedItem => selectedItem.unique_id === player.unique_id)}
                                                onChange={() => handleSelectItem(player.unique_id)}
                                            />
                                        </td>
                                        <td style={{ width: '17%', textAlign: 'start' }}> <img src={player.profile_image_url_https == '' ? "/images/nopic.png" : player.profile_image_url_https} /> {player.screen_name}</td>
                                        <td style={{ width: '15%' }}> {player.invite1 === null ? '---' : player.invite1}</td>
                                        <td style={{ width: '15%' }}> {player.invite2 === null ? '---' : player.invite2}</td>
                                        <td style={{ width: '15%' }}> {player.invite3 === null ? '---' : player.invite3}</td>
                                        <td style={{ width: '29%', fontSize: '10px' }}>{player.wallet}</td>
                                        <td style={{ width: '5%' }}><span onClick={() => {
                                            setWarningInfo({
                                                Text: `Are you sure you want to delete ${player.screen_name}?`,
                                                Button: 'DELETE',
                                                Function: () => {
                                                    setIsWarningOpened(false)
                                                }
                                            })
                                            setIsWarningOpened(true)
                                        }} className="delete">X</span></td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div> : <table>
                    <thead>
                        <tr>
                            <th>
                                <StyledCheckbox
                                    type="checkbox"
                                    checked={allCheck}
                                    onChange={() => { selectAllItems() }}
                                />
                                {allCheck &&
                                    <img onClick={() => selectAllItems()} className="img-tick2" src="/images/Asset-1.png" />
                                }
                            </th>
                            <th>Applicants</th>
                            <th>Invite 1</th>
                            <th>Invite 2</th>
                            <th>Invite 3</th>
                            <th>Applicants Wallet</th>
                            <th>Delete</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr className="bosluk"></tr>

                        {pageTableList.map((player, index) => {

                            return (
                                <tr key={Math.random() * 1000}>
                                    <td style={{ width: '4%' }}>

                                        <StyledCheckbox
                                            type="checkbox"
                                            checked={selectedItems.some(selectedItem => selectedItem.unique_id === player.unique_id)}
                                            onChange={() => handleSelectItem(player.unique_id)}
                                        />
                                        {selectedItems.some(selectedItem => selectedItem.unique_id === player.unique_id) &&
                                            <img onClick={() => handleSelectItem(player.unique_id)} className="img-tick" src="/images/Asset-1.png" />
                                        }
                                    </td>
                                    <td style={{ width: '17%', textAlign: 'start' }}> <img src={player.profile_image_url_https == '' ? "/images/nopic.png" : player.profile_image_url_https} /> {player.screen_name}</td>
                                    <td style={{ width: '15%' }}> {player.invite1 === null ? '---' : player.invite1}</td>
                                    <td style={{ width: '15%' }}> {player.invite2 === null ? '---' : player.invite2}</td>
                                    <td style={{ width: '15%' }}> {player.invite3 === null ? '---' : player.invite3}</td>
                                    <td style={{ width: '27%', fontSize: '12px' }}>{player.wallet}</td>
                                    <td style={{ width: '7%' }}><span className="delete" onClick={() => {
                                        setWarningInfo({
                                            Text: `Are you sure you want to delete ${player.screen_name}?`,
                                            Button: 'DELETE',
                                            Function: () => {
                                                axios.post(api + '/api/deleteUser', {
                                                    id: player.unique_id
                                                }, {
                                                    auth: {
                                                        username: adminControl.user,
                                                        password: adminControl.password
                                                    }
                                                }).then(res => {
                                                    toast.success('Successfully deleted.')
                                                    setIsWarningOpened(false)
                                                })
                                                    .catch(e => toast.error(e.response.data.error))
                                                setIsWarningOpened(false)
                                            }
                                        })
                                        setIsWarningOpened(true)
                                    }}>X</span></td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>}
                <div className="pagination">
                    <ul>

                        <li className="prev" onClick={() => {
                            if (currentSlideIndex === 1) {
                                return
                            } else {
                                setIsLoading(true)
                                setTimeout(() => {
                                    setCurrentSlideIndex(prev => prev - 1)
                                    setIsLoading(false)
                                    window.scrollTo(0, 0);

                                }, 200);
                            }
                        }}>Prev</li>

                        <li style={{ letterSpacing: '2px' }}>{currentSlideIndex}/</li>
                        <li onClick={() => setCurrentSlideIndex(allPages === 0 ? 1 : allPages)}> {allPages === 0 ? 1 : allPages}</li>

                        <li className="next" onClick={() => {
                            if (currentSlideIndex === allPages || allPages < 1) {
                                return
                            } else {
                                setIsLoading(true)
                                setTimeout(() => {
                                    window.scrollTo(0, 0);
                                    setCurrentSlideIndex(prev => prev + 1)
                                    setIsLoading(false)
                                }, 200);
                            }
                        }}>Next</li>
                    </ul>
            
                </div>

            </div>}
        </AdminPageStyled>
    )
}

const StyledCheckbox = styled.input`
 appearance: none;
  width: 20px;
  height: 20px;
  
  border-radius: 4px;
  background-color: ${props => props.checked ? 'green' : 'white'};
  color: white;
  border: 2px solid #4CAF50;
  position: relative;
  top: 4px;
  outline: none;
  cursor: pointer;
  
  &:checked {
    background-color: #4CAF50; 
  }

  &:focus {
    box-shadow: 0 0 0 3px rgba(0, 128, 0, 0.5); 
  }

`;
export default AdminPage;
export const AdminPageStyled = styled.div`
  position: relative;
  z-index: 2;
  min-height: 100vh;
  height: auto;
  background-color: white;

  .container{
    max-width: 1200px;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .header{
        width: 100%;
        margin-top: 30px;
        display: flex;
        justify-content: space-between;
        .application{
            padding: 0px;
            padding-top: 0px;
            margin-top: 30px;
            border-radius: 16px;
            box-shadow: 0px 14.259262084960938px 35.648155212402344px -7.129631042480469px rgba(0, 0, 0, 0.05);
            box-shadow: 0px 7.129631042480469px 7.129631042480469px -3.5648155212402344px rgba(0, 0, 0, 0.1);
            width: 240px;
            height: 120px;
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            align-items: center;
            background-color: white;
            h1{
                font-family: bold-segoe;
                font-size: 24px;
                line-height: 32px;
                font-weight: 100;
            }
            /* p{
                font-family: regular-segoe;
                font-size: 25px;
                line-height: 30px;
                font-weight: 400;
                padding-left: 20px;
            } */
            @media (max-width: 920px) {
                
                max-width: 50% ;
                height: 120px;
                h1{
                    font-size: 22px;
                    line-height: 40px;
                padding-left: 12px;
            }
            p{
                font-size: 18px;
                line-height: 30px;
                padding-left: 12px;
            }
            }
        }
        .selectAll{
            width: 500px;
            display: flex;
            flex-direction: column;
            justify-content: start;
            margin-top: 60px;
            @media (max-width: 920px) {
                width: auto;
                    min-width: 200px;
                }
            h1{
                font-family: bold-segoe;
                font-size: 24px;
                line-height: 31px;
                font-weight: 100;
                position: relative;
                bottom: 6px;
                padding-right: 12px;
                text-align: end;
            }
            .button-container{
                display: flex;
                flex-direction: row;
                gap: 10px;
                @media (max-width: 920px) {
                    flex-direction: column;
                }
                .button1{
                    background-color: #4CAF50;
                    font-size: 16px;
                    line-height: 25px;
                    padding-top: 10px;
                    font-family: regular-segoe;
                    font-weight: 600;
                    color: white;
                    text-align: center;
                    cursor: pointer;
                    transition: all 0.3s ease-in-out;
                    width: 180px;
                    height: 48px; 
                    border-radius: 16px;
                    box-shadow: 0px 14.259262084960938px 35.648155212402344px -7.129631042480469px rgba(0, 0, 0, 0.05);
                    box-shadow: 0px 7.129631042480469px 7.129631042480469px -3.5648155212402344px rgba(0, 0, 0, 0.1);
                    box-shadow: 0px -3.5648155212402344px 14.259262084960938px 0px rgba(0, 0, 0, 0.05) inset;
                    margin-top: 8px;
                    @media (max-width: 920px) {
                        width: 160px;
                    height: 36px; 
                    font-size: 15px;
                    line-height: 22px;
                    padding-top: 6px;
                    font-family: regular-segoe;
                    font-weight: 600;
                    margin-top: 0px;
                    }
                    :hover{
                        opacity: 0.7;
                    }
                }
                .button2{
                    width: 200px;
                    height: 48px; 
                    background-color: #DD4F4F;
                    font-size: 16px;
                    line-height: 25px;
                    padding-top: 10px;
                    font-family: regular-segoe;
                    font-weight: 600;
                    color: white;
                    text-align: center;
                    cursor: pointer;
                    transition: all 0.3s ease-in-out;
                    border-radius: 16px;
                    box-shadow: 0px 14.259262084960938px 35.648155212402344px -7.129631042480469px rgba(0, 0, 0, 0.05);
                    box-shadow: 0px 7.129631042480469px 7.129631042480469px -3.5648155212402344px rgba(0, 0, 0, 0.1);
                    box-shadow: 0px -3.5648155212402344px 14.259262084960938px 0px rgba(0, 0, 0, 0.05) inset;
                    margin-top: 8px;
                    @media (max-width: 920px) {
                        width: 160px;
                    height: 36px; 
                    font-size: 15px;
                    line-height: 22px;
                    padding-top: 6px;
                    font-family: regular-segoe;
                    font-weight: 600;
                    margin-top: 0px;
                    }
                    :hover{
                        opacity: 0.7;
                    }
                }
                .button3{
                    width: 120px;
                    position: relative;
                    height: 48px; 
                    background-color: #707070;
                    font-size: 16px;
                    line-height: 25px;
                    padding-top: 10px;
                    font-family: regular-segoe;
                    font-weight: 600;
                    color: white;
                    text-align: center;
                    cursor: pointer;
                    transition: all 0.3s ease-in-out;
                    border-radius: 16px;
                    box-shadow: 0px 14.259262084960938px 35.648155212402344px -7.129631042480469px rgba(0, 0, 0, 0.05);
                    box-shadow: 0px 7.129631042480469px 7.129631042480469px -3.5648155212402344px rgba(0, 0, 0, 0.1);
                    box-shadow: 0px -3.5648155212402344px 14.259262084960938px 0px rgba(0, 0, 0, 0.05) inset;
                    margin-top: 8px;
                    padding-left: 20px;
                    @media (max-width: 920px) {
                        width: 160px;
                    height: 36px; 
                    font-size: 15px;
                    line-height: 22px;
                    padding-top: 6px;
                    font-family: regular-segoe;
                    font-weight: 600;
                    margin-top: 0px;
                    padding-left: 0px;
              
                    }
                    svg{
                        width: 20px;
                        height: 20px;
                        position: absolute;
                        left: 10px;
                        top: 13px;
                        @media (max-width: 920px) {
                            top: 8px;
                    }
                    }
                    :hover{
                        opacity: 0.7;
                    }
                }
            }
            @media (max-width: 920px) {
                padding: 10px;
            padding-top: 0px;
            display: flex;
            align-items: center;
            max-width: 50%;
            height: 120px;
            margin-top: 8px;
            h1{
                font-size: 22px;
                line-height: 36px;
                padding-left: 0px;
            }
            .button1{
                width: 120px;
                height: 24px;
                font-size: 13px;
                line-height: 20px;  
                padding-top: 2px;
                :hover{
                    opacity: 0.7;
                }
            }
            .button2{
                width: 120px;
                height: 24px;
                font-size: 13px;
                line-height: 20px;  
                padding-top: 2px;
                :hover{
                    opacity: 0.7;
                }
            }
            }
        }
    }
    .tableContainer{
       
    @media (max-width: 920px) {
        border-radius: 20px;
    display: flex;
    flex-direction: column;
    height: auto;
    overflow-x: auto;
    overflow-y: hidden;
    padding-block: 8px;
    ::-webkit-scrollbar {
     width: 10px;
    height: 8px;
    }

::-webkit-scrollbar-track {
  background: #fff;
  
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #707070;
  border-radius: 12px;

}

      width: 97vw;
    }
   }
    table{
        width: 100%;
        margin-top: 40px;
        background-color: white;
        @media (max-width: 920px) {
        border-collapse: collapse;
        border-spacing: 0;
         width: 100%;
         }

         .img-tick{
    position: absolute;
    cursor: pointer;
    width: 16px;
    height: 16px;
    left: 7px;
     top: 32px;
  }

        thead{
            box-shadow: 0px 14.259262084960938px 35.648155212402344px -7.129631042480469px rgba(0, 0, 0, 0.05);
            box-shadow: 0px 7.129631042480469px 7.129631042480469px -3.5648155212402344px rgba(0, 0, 0, 0.1);
            border-radius: 20px;
            background-color: white;
            th{
                font-family: bold-segoe;
                font-weight: 400;
                padding-top: 23px;
                font-size: 16px;
                color: rgb(0,0,0,0.5);
                background-color: white;
                height: 75px;
                text-align: center;
                align-items: center;
                position: relative;
                .img-tick2{
                    position: absolute;
                    z-index: 99;
                    cursor: pointer;
                    width: 16px;
                    height: 16px;
                    left: 35%;
                    top: 29px;
            }
                :nth-child(odd){
                    background-color: whitesmoke;
                }
                :nth-child(1){
                    border-radius: 16px 0px 0px 16px;
                }
                :nth-last-child(1){
                    border-radius: 0px 16px 16px 0px;
                }
            }
        }  
            tbody{
                box-shadow: 0px 14.259262084960938px 35.648155212402344px -7.129631042480469px rgba(0, 0, 0, 0.05);
                box-shadow: 0px 7.129631042480469px 7.129631042480469px -3.5648155212402344px rgba(0, 0, 0, 0.1);
                border-radius: 20px;
                background-color: white;
                .bosluk{
                    height: 30px;
                    @media (max-width: 920px) {
                        height: 10px;
                    }
                }
                
                tr{
                    :nth-child(2){
                    td{
                        :nth-child(1){
                            border-radius: 16px 0px 0px 0px;
                        }
                        :nth-last-child(1){
                            border-radius: 0px 16px 0px 0px;
                        }
                    }
                }
                    :nth-last-child(1){
                        td{
                            :nth-last-child(1){
                                border-radius: 0px 0px 16px 0px;
                            }
                            :nth-child(1){
                                border-radius: 0px 0px 0px 16px;
                            }
                        }
                    }
                }
            td{
                user-select: text !important;
                -webkit-user-select: text !important; /* Safari */
                -moz-user-select: text !important; /* Firefox */
                -ms-user-select: text !important; /* Internet Explorer/Edge */
                font-family: regular-segoe;
                font-weight: 500;
                padding-top: 27px;
                background-color: white;
                text-align: center;
                color: rgb(0,0,0,0.7);
                height: 75px;
                position: relative;
                font-size: 14px;
                font-family: regular-segoe;
                /* filter: drop-shadow(-1px 2px 1px rgb(112, 112, 112, 0.2)); */
               img{
                margin-left: 10px;
                margin-right: 10px;
                border-radius: 50%;
                position: relative;
                top: 0px;
                bottom: 13px;
                width: 30px;
                height: 30px;
               }
               .plus{
                position: absolute;
                width: 32px;
                height: 32px;
                background-color: #74DE44;
                font-size: 32px;
                font-weight: 400;
                color: white;
                border-radius: 16px;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                cursor: pointer;
                transition: all 0.3s ease-in-out;
                :hover{
                    opacity: 0.7;
                }
               }
               .delete{
                position: absolute;
                width: 30px;
                height: 30px;
                background-color: #DD4F4F;
                font-size: 24px;
                padding-top: 4px;
                font-weight: 400;
                color: white;
                border-radius: 16px;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                cursor: pointer;
                transition: all 0.3s ease-in-out;
                :hover{
                    opacity: 0.7;
                }
               }
               @media (max-width: 920px) {
                padding-top: 17px;
                min-width: 160px;
                height: 55px;
                font-size: 15px;
               img{
                margin-left: 4px;
              
               }
               .plus{
                position: absolute;
                width: 32px;
                height: 32px;
                background-color: #74DE44;
                font-size: 32px;
                font-weight: 400;
                color: white;
                border-radius: 16px;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                cursor: pointer;
                transition: all 0.3s ease-in-out;
                :hover{
                    opacity: 0.7;
                }
               }
               .delete{
                position: absolute;
                width: 32px;
                height: 32px;
                background-color: #DD4F4F;
                font-size: 26px;
                padding-top: 4px;
                font-weight: 400;
                color: white;
                border-radius: 16px;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                cursor: pointer;
                transition: all 0.3s ease-in-out;
                :hover{
                    opacity: 0.7;
                }
               }
                }
    
            }
            td:nth-child(odd){
                background-color: whitesmoke;
            }
        }
    }
    .pagination{
        width: 100%;
        margin-bottom: 60px;
          display: flex;
            flex-direction: row;
            justify-content: center;
            position: relative;
        ul{
            margin-top: 40px;
            width: 120px;
            display: flex;
            flex-direction: row;
            justify-content: center;
            li{
                font-family: regular-segoe;
                font-weight: 600;
                font-size: 15px;
                opacity: 0.6;
                background-color: white;
                transition: all 0.3s ease-in-out;
           
            }
            .prev{
                padding-right: 12px;
                opacity: 0.6;
                cursor: pointer;

                :hover{
                    opacity: 1;
                }
            }
            .next{
                padding-left: 12px;
                opacity: 0.6;
                cursor: pointer;

                :hover{
                    opacity: 1;
                }
            }
        }
    }
}
.loading{
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: black;
    opacity: 0.7;
    z-index: 100000;
    height: 100%;
    width: 100%;
    svg{
      position: absolute;
      width: 200px;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      z-index: 100000;
      height: 200px;
    }
  }

`;

