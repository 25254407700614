import styled from "styled-components";
import { useState, useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import axios from "axios";

const Warning = ({ warningInfo, setIsWarningOpened, adminControl }) => {
    const [isLoading, setIsLoading] = useState(false);
    const api = 'https://fomo.lodosyazilim.com.tr'

    const selectDownload=(format)=>{
        axios.request({
            responseType: 'blob',
            url: api + `/api/exportUser?type=${format}`,
            method: 'GET',
            headers: {
            'Content-Type': 'blob',
            
            },
            auth:{
                username:adminControl.user,
                password:adminControl.password
            }
          }).then((response) => {
            const href = URL.createObjectURL(response.data);
            const link = document.createElement('a');
            link.href = href;
            link.setAttribute('download', `Fomo-Users.${format}`); //or any other extension
            document.body.appendChild(link);
            link.click();
        
            document.body.removeChild(link);
            URL.revokeObjectURL(href);
            });
    }

    return (
        <WarningStyled>
            <div className="container">
                <div className="form">
                    {/* <h1> Add a new member</h1> */}
                {warningInfo.exported ? <div>    
                <div className="inputs">
                        <div className="warning-container" style={{backgroundColor:'#4CAF50'}}>
                           <div>
                            <span>?</span>
                           </div>
                  
                        </div>
                        <div>
                            <h3 style={{marginBlock:'30px'}}>Select the download format.</h3>

                        </div>
                    </div>
                    <div className="button-container">
                    {/* // csv,xlsx,xls,json */}

                        <button style={{backgroundColor:'gray'}} onClick={()=>selectDownload('csv')}>CSV</button>
                        <button style={{backgroundColor:'gray'}} onClick={()=>selectDownload('xlsx')}>XLSX</button>
                        <button style={{backgroundColor:'gray'}} onClick={()=>selectDownload('xls')}>XLS</button>
                        <button style={{backgroundColor:'gray'}} onClick={()=>selectDownload('json')}>JSON</button>
                    </div>
                     </div> : <>    
                <div className="inputs">
                        <div className="warning-container">
                           <div>
                            <span>!</span>
                           </div>
                  
                        </div>
                        <div>
                            <h3 style={{marginTop:'30px'}}>{warningInfo.Text}</h3>

                        </div>
                    </div>
                    <div className="button-container">
                        <button onClick={()=>setIsWarningOpened(false)}>CANCEL</button>
                        <button  style={{backgroundColor: '#DD4F4F'}} onClick={warningInfo.Function}>{warningInfo.Button}</button>
                    </div>
                    </>}
                </div>
                
                <div className="close" onClick={() => setIsWarningOpened(false)}>
                    x
                </div> 
            </div>

        </WarningStyled>
    );
};

export default Warning;
export const WarningStyled = styled.div`
  z-index: 2;
  height: 100%;
  overflow: hidden;
  position: absolute;
  width: 100%;
  z-index: 99;
  background-color: rgba(0,0,0, 0.4);
  display: flex;
    flex-direction: column;
  .container{
    animation: openModal 0.3s ease-in-out;
    position: fixed;
    max-width: 1200px;
    min-width: 500px;
    top: 50vh;
    left: 50%;
    transform: translate(-50%, -50%);
    @media (max-width: 920px) {
        min-width: 92vw ;
    }
    height: 350px;
    background-color: white;
    border-radius: 24px;
    filter: drop-shadow(5px 4px 5px rgb(112, 112, 112, 1));
    .form{
        height: 100%;
        margin-inline: 40px;
        @media (max-width: 920px) {
            margin-inline: 10px;
    }
        display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-block: 50px;
    align-items: center;
    h1{
        font-size: 24px;
        font-weight: 600;
        font-family: regular-segoe;
    }
    .inputs{
        margin-top: 0px;
        display: flex;
        flex-direction: column;
        align-items: center;
        .warning-container{
            width: 80px;
            height: 80px;
            background-color: #DD4F4F;
            border-radius: 50%;
            position: relative;
            top: 6px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            div{
                width: 50px;
                height: 50px;
                margin-top: 10px;
                border: 1px solid white;
                border-radius: 50%;
                text-align: center;
                span{
                   line-height: 50px;
                   font-size: 32px;
                    color: white;
                    text-align: center;
                }
            }
        }
        div{
            margin-bottom: 10px;
            h3{
                text-align: center;
                font-size: 19px;
                font-weight: 500;
                font-family: regular-segoe;
                padding-top: 20px;
            }
            input{
                width: 100%;
  padding: 12px 16px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  font-family: regular-segoe;

            }
        }
    }
    .button-container{
        display: flex;
        gap: 30px;
        button{
            width: 100%;
            min-width: 100px;
            background-color: #4CAF50;
            color: white;
            padding: 14px 20px;
            margin: 8px 0;
            border: none;
            border-radius: 4px;
            margin-bottom: -10px;
            cursor: pointer;
            transition: all 0.3s ease-in-out;
            :hover{
                opacity: 0.8;
            }
        }
    }
    
    }

    .close{
        position: absolute;
        top: 10px;
        right: 10px;
        width: 20px;
        height: 20px;
        font-size: 19px;
        line-height: 16px;
        font-weight: 300;
        border: 0.5px solid black;
        border-radius: 50%;
        text-align: center;
        transition: all 0.3s ease-in-out;
        cursor: pointer;
        :hover{
            border: 2px solid black;
            line-height: 13px;
            transform: scale(1.2);
        }
    }
  }
  @keyframes openModal {
    0%{
       opacity:0;
    }100%{
        opacity:1;
 }
  }
`;
