import styled from "styled-components";
import { useState, useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import axios from "axios";

const AddModal = ({ isModalOpened, setIsModalOpened, adminControl }) => {
    const [userName, setUserName] = useState('');
    const [walletAdrees, setWalletAdrees] = useState('');
    const [invite1, setInvite1] = useState('');
    const [invite2, setInvite2] = useState('');
    const [invite3, setInvite3] = useState('');
    
    
    const api = 'https://fomo.lodosyazilim.com.tr'

    const addUser=()=>{
        console.log('sdasd');
        axios.post(api+'/api/addUser',{
            name: userName,
            wallet: walletAdrees,
            invite1: invite1,
            invite2: invite2,
            invite3: invite3
        },{
            auth:{
                username:adminControl.user,
                password: adminControl.password
            }
        }).then(()=>{
            toast.success('Successfully added');
            setIsModalOpened(false)})
        .catch(e=> toast.error(e.response.data.error))
       
    }

    return (
        <AddModalStyled>
       <ToastContainer
      position="top-right"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      theme="dark"
    />
            <div className="container">
                <div className="form">
                    {/* <h1> Add a new member</h1> */}
                    <div className="inputs">
                        <div>
                            <h3>Applicant Name</h3>
                            <input type="text"  onChange={(e)=>setUserName(e.target.value)}/>
                        </div>
                        <div>
                            <h3>Friend's Twitter Username 1</h3>
                            <input type="text" onChange={(e)=>setInvite1(e.target.value)}/>
                        </div>
                        <div>
                            <h3>Friend's Twitter Username 2</h3>
                            <input type="text" onChange={(e)=>setInvite2(e.target.value)}/>
                        </div>
                        <div>
                            <h3>Friend's Twitter Username 3</h3>
                            <input type="text" onChange={(e)=>setInvite3(e.target.value)}/>
                        </div>
                        <div>
                            <h3>Wallet Adress</h3>
                            <input type="text" onChange={(e)=>setWalletAdrees(e.target.value)}/>
                        </div>
                    </div>
                    <div>
                        <button onClick={()=>addUser()}>ADD</button>
                    </div>
                </div>

                <div className="close" onClick={() => setIsModalOpened(false)}>
                    x
                </div>
            </div>

        </AddModalStyled>
    );
};

export default AddModal;
export const AddModalStyled = styled.div`
  z-index: 2;
  height: 100%;
  overflow: hidden;
  position: absolute;
  width: 100%;
  z-index: 99;
  background-color: rgba(0,0,0, 0.4);
  display: flex;
  flex-direction: column;
  .container{
    animation: openModal 0.3s ease-in-out;
    position: fixed;
    max-width: 1200px;
    min-width: 500px;
    top: 50vh;
    left: 50%;
    transform: translate(-50%, -50%);
    @media (max-width: 920px) {
        min-width: 100vw ;
    }
    height: 680px;
    background-color: white;
    border-radius: 24px;
    filter: drop-shadow(5px 4px 5px rgb(112, 112, 112, 1));

    .form{
        height: 100%;
        margin-inline: 40px;
        @media (max-width: 920px) {
            margin-inline: 20px;
    }
        display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-block: 50px;
    align-items: center;
    h1{
        font-size: 24px;
        font-weight: 600;
        font-family: regular-segoe;
    }
    .inputs{
        margin-top: 10px;
        div{
            margin-bottom: 10px;
            h3{
                text-align: center;
                font-size: 19px;
                font-weight: 500;
                font-family: regular-segoe;
                padding-block: 8px;
            }
            input{
                width: 100%;
                margin: 4px 0px;
                margin-right: 50px;
                padding: 12px 16px;
                display: inline-block;
                border: 1px solid #ccc;
                border-radius: 4px;
                box-sizing: border-box;
                font-family: regular-segoe;

            }
        }
    }
    button{
        width: 100%;
        background-color: #4CAF50;
        color: white;
        padding: 14px 21px;
        border: none;
        border-radius: 4px;
        margin-bottom: -20px;
        cursor: pointer;
        transition: all ease-in-out 0.3s;
        :hover{
            opacity: 0.7;
        }
    }
    }

    .close{
        position: absolute;
        top: 10px;
        right: 10px;
        width: 20px;
        height: 20px;
        font-size: 19px;
        line-height: 16px;
        font-weight: 300;
        border: 0.5px solid black;
        border-radius: 50%;
        text-align: center;
        transition: all 0.3s ease-in-out;
        cursor: pointer;
        :hover{
            border: 2px solid black;
            line-height: 13px;
            transform: scale(1.2);
        }
    }
  }
  @keyframes openModal {
    0%{
       opacity: 0;
    }100%{
        opacity: 1;
    }
  }
`;
